<template>
  <v-dialog v-model="dialogState" persistent max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left>mdi-barcode</v-icon>
          {{ $vuetify.lang.t("$vuetify.navigations.ai_generator") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-if="!download">
          <v-form>
            <div v-for="(item, index) in fields" :key="index">
              <v-row align="start" v-if="fieldsFilled.length > 0">
                <v-col sm=12>
                  <v-autocomplete
                    v-model="fieldsFilled[index]['key']"
                    :items="aiList"
                    @change="validate(index)"
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col sm-12>
                  <v-text-field
                    v-model="fieldsFilled[index]['value']"
                    @change="validate(index, true)"
                    @keyup="validate(index, true)"
                    :required="fieldsFilled[index]['key'] != null"
                    :disabled="fieldsFilled[index]['key'] == null"
                    :placeholder="placeholderValues[index]"
                    :error="fieldsFilled[index]['error']"
                    :error-messages="fieldsFilled[index]['error_msg']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <v-btn small @click="addMore" :disabled="errors > 0">
            <v-icon class="mr-3">mdi-plus</v-icon>
            {{ $vuetify.lang.t("$vuetify.action.add_field") }}
          </v-btn>
        </div>
        <div v-else>
          <center class="pt-5">
            <h2 class="text-success">
              {{ $vuetify.lang.t("$vuetify.action.download_ready") }}
            </h2>
            <v-btn :href="download" v-if="download" class="mt-5" @click="closePopup">
              <v-icon class="mr-3">mdi-download</v-icon>
              {{ $vuetify.lang.t("$vuetify.action.download") }}
            </v-btn>
          </center>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn
          @click="generate()"
          :disabled="errors > 0"
          v-if="!download"
          :loading="loading"
        >
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import ai from "@/plugins/gs1ai.js";

export default {
  data: function() {
    return {
      dialogState: this.aiGeneratorState,
      aiList: false,
      fieldsFilled: [],
      fields: [],
      field: { key: null, value: null, error: false },
      download: false,
      valid: false,
      placeholders: [],
      placeholderValues: [],
      errors: 1,
      loading: false,
    };
  },
  methods: {
    addMore() {
      this.fields.push(this.field);
      this.fieldsFilled.push(JSON.parse(JSON.stringify(this.field)));
      this.placeholderValues[this.fields.count] = "";
    },
    closePopup() {
      this.dialogState = false;
      this.download = false;
      this.errors = 1;
      this.valid = false;
      this.fieldsFilled = [];
      this.$root.aiGeneratorState = false;
    },
    validate(index, v) {
      var selected = this.fieldsFilled[index]["key"];
      var value = this.fieldsFilled[index]["value"];
      this.placeholderValues[index] = selected.placeholder;

      if(!v) {
        this.valid = false;
        this.errors = 1;
        this.fieldsFilled[index]["error"] = false;
        this.fieldsFilled[index]["error_msg"] = selected.placeholder;
      }

      if (value == null) {
        this.valid = false;
        this.errors = 1;
        return false;
      }

      var valueNoCode = v ? value : null;
      var regex = new RegExp(selected.regex);

      if (
        selected.code == "01" ||
        (selected.code == "02" && valueNoCode.length < 14)
      ) {
        valueNoCode = valueNoCode.padStart(14, "0");
      } else if (selected.code == "00" && valueNoCode.length < 18) {
        valueNoCode = valueNoCode.padStart(18, "0");
      }

      if (regex.test(valueNoCode)) {
        this.valid = true;
        this.errors = 0;
        this.fieldsFilled[index]["error"] = false;
        this.fieldsFilled[index]["error_msg"] = "";
        return true;
      }

      this.valid = false;
      this.errors = 1;
      this.fieldsFilled[index]["error"] = true;
      this.fieldsFilled[index]["error_msg"] = selected.placeholder;
    },
    generate() {
      this.loading = true;
      var data = {
        barcode_type: "code128",
        barcode_string: "",
      };

      this.fieldsFilled.forEach((e) => {
        if (e.value != null) {
          var val = e.value;
          if (
            e.key.code == "01" ||
            (e.key.code == "02" && e.value < 14)
          ) {
            val = e.value.padStart(14, "0");
          } else if (e.key.code == "00" && e.value < 18) {
            val = e.value.padStart(18, "0");
          }

          data["barcode_string"] += "(" + e.key.code + ")" + val;
        }
      });

      Api.ai("generate", data)
        .then((d) => {
          this.download = d.data.url;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    "$root.aiGeneratorState": function(newVal) {
      this.dialogState = newVal;
      this.loading = false;
      if (newVal) {
        var kVals = [];
        ai.forEach(function(element) {
          kVals.push({
            code: element[0],
            text: element[1],
            placeholder: element[2],
            regex: element[4],
          });
        });
        this.aiList = kVals;
        this.fields = [];
        this.fieldsFilled = [];
        this.addMore();
      }
    },
  }
};
</script>
