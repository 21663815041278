<template>
  <div @click="closeNav">
    <v-app id="inspire">
      <vue-headful :title="'GS1 Georgia'" />
      <v-navigation-drawer
        v-model="drawer"
        enable-route-watcher
        app
        enable-resize-watcher
        :iconName="'my_icon'"
      >
        <NavigationList />
      </v-navigation-drawer>

      <v-app-bar app color="primary" dark>
        <v-row fluid>
          <v-col lg="10">
            <v-app-bar-nav-icon
              class="float-left"
              @click.stop="drawer = !drawer"
            />
            <router-link to="/manager">
              <v-img
                v-if="
                  $route.meta.provider != 'solution' ||
                    $route.params.type == 'membership'
                "
                class="mx-2 mt-1 float-left"
                src="@/assets/img/gs1_logo.png"
                max-height="40"
                max-width="75"
              ></v-img>
              <v-img
                v-else
                class="mx-2 mt-1 float-left"
                src="@/assets/img/sol2.png"
                max-height="40"
                max-width="45"
              ></v-img>
            </router-link>
          </v-col>
          <v-col lg="2">
            <LanguageChange class="float-right" />
          </v-col>
        </v-row>
      </v-app-bar>

      <router-view class="pa-5"></router-view>
      <AiGenerator />

      <v-footer color="primary" app>
        <span class="white--text">&copy; {{currentYear}} GS1 Georgia</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import NavigationList from "@/components/manager/Navigation";
import LanguageChange from "@/components/shared/Language";
import AiGenerator from "@/components/manager/popup/AiGenerator";
export default {
  props: {
    source: String,
  },
  components: {
    NavigationList,
    LanguageChange,
    AiGenerator,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
