var ai =[
   [
      "00",
      "00 - Serial Shipping Container Code (SSCC)",
      "N18",
      "SSCC",
      "^(\\d{18})$"
   ],
   [
      "01",
      "01 - Global Trade Item Number (GTIN)",
      "N14",
      "GTIN",
      "^(\\d{14})$"
   ],
   [
      "02",
      "02 - Global Trade Item Number (GTIN) of contained trade items",
      "N14",
      "CONTENT",
      "^(\\d{14})$"
   ],
   [
      "10",
      "10 - Batch or lot number",
      "X..20",
      "BATCH/LOT",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "11",
      "11 - Production date (YYMMDD)",
      "N6",
      "PROD DATE",
      "^(\\d{6})$"
   ],
   [
      "12",
      "12 - Due date (YYMMDD)",
      "N6",
      "DUE DATE",
      "^(\\d{6})$"
   ],
   [
      "13",
      "13 - Packaging date (YYMMDD)",
      "N6",
      "PACK DATE",
      "^(\\d{6})$"
   ],
   [
      "15",
      "15 - Best before date (YYMMDD)",
      "N6",
      "BEST BEFORE or BEST BY",
      "^(\\d{6})$"
   ],
   [
      "16",
      "16 - Sell by date (YYMMDD)",
      "N6",
      "SELL BY",
      "^(\\d{6})$"
   ],
   [
      "17",
      "17 - Expiration date (YYMMDD)",
      "N6",
      "USE BY OR EXPIRY",
      "^(\\d{6})$"
   ],
   [
      "20",
      "20 - Internal product variant",
      "N2",
      "VARIANT",
      "^(\\d{2})$"
   ],
   [
      "21",
      "21 - Serial number",
      "X..20",
      "SERIAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "22",
      "22 - Consumer product variant",
      "X..20",
      "CPV",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "235",
      "235 - Third Party Controlled, Serialised Extension of GTIN (TPX)",
      "X..28",
      "TPX",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,28})$"
   ],
   [
      "240",
      "240 - Additional product identification assigned by the manufacturer",
      "X..30",
      "ADDITIONAL ID",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "241",
      "241 - Customer part number",
      "X..30",
      "CUST. PART NO.",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "242",
      "242 - Made-to-Order variation number",
      "N..6",
      "MTO VARIANT",
      "(\\d{0,6})$"
   ],
   [
      "243",
      "243 - Packaging component number",
      "X..20",
      "PCN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "250",
      "250 - Secondary serial number",
      "X..30",
      "SECONDARY SERIAL",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "251",
      "251 - Reference to source entity",
      "X..30",
      "REF. TO SOURCE",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "253",
      "253 - Global Document Type Identifier (GDTI)",
      "N13+X..17",
      "GDTI",
      "(\\d{13})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,17})$"
   ],
   [
      "254",
      "254 - Global Location Number (GLN) extension component",
      "X..20",
      "GLN EXTENSION COMPONENT",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "255",
      "255 - Global Coupon Number (GCN)",
      "N13+N..12",
      "GCN",
      "(\\d{13})(\\d{0,12})$"
   ],
   [
      "30",
      "30 - Variable count of items (variable measure trade item)",
      "N..8",
      "VAR. COUNT",
      "^(\\d{0,8})$"
   ],
   [
      "3100",
      "3100 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3101",
      "3101 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3102",
      "3102 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3103",
      "3103 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3104",
      "3104 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3105",
      "3105 - Net weight, kilograms (variable measure trade item)",
      "N6",
      "NET WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3110",
      "3110 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3111",
      "3111 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3112",
      "3112 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3113",
      "3113 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3114",
      "3114 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3115",
      "3115 - Length or first dimension, metres (variable measure trade item)",
      "N6",
      "LENGTH (m)",
      "(\\d{6})$"
   ],
   [
      "3120",
      "3120 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3121",
      "3121 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3122",
      "3122 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3123",
      "3123 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3124",
      "3124 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3125",
      "3125 - Width, diameter, or second dimension, metres (variable measure trade item)",
      "N6",
      "WIDTH (m)",
      "(\\d{6})$"
   ],
   [
      "3130",
      "3130 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3131",
      "3131 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3132",
      "3132 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3133",
      "3133 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3134",
      "3134 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3135",
      "3135 - Depth, thickness, height, or third dimension, metres (variable measure trade item)",
      "N6",
      "HEIGHT (m)",
      "(\\d{6})$"
   ],
   [
      "3140",
      "3140 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3141",
      "3141 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3142",
      "3142 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3143",
      "3143 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3144",
      "3144 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3145",
      "3145 - Area, square metres (variable measure trade item)",
      "N6",
      "AREA (m2)",
      "(\\d{6})$"
   ],
   [
      "3150",
      "3150 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3151",
      "3151 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3152",
      "3152 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3153",
      "3153 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3154",
      "3154 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3155",
      "3155 - Net volume, litres (variable measure trade item)",
      "N6",
      "NET VOLUME (l)",
      "(\\d{6})$"
   ],
   [
      "3160",
      "3160 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3161",
      "3161 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3162",
      "3162 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3163",
      "3163 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3164",
      "3164 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3165",
      "3165 - Net volume, cubic metres (variable measure trade item)",
      "N6",
      "NET VOLUME (m3)",
      "(\\d{6})$"
   ],
   [
      "3200",
      "3200 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3201",
      "3201 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3202",
      "3202 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3203",
      "3203 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3204",
      "3204 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3205",
      "3205 - Net weight, pounds (variable measure trade item)",
      "N6",
      "NET WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3210",
      "3210 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3211",
      "3211 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3212",
      "3212 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3213",
      "3213 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3214",
      "3214 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3215",
      "3215 - Length or first dimension, inches (variable measure trade item)",
      "N6",
      "LENGTH (in)",
      "(\\d{6})$"
   ],
   [
      "3220",
      "3220 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3221",
      "3221 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3222",
      "3222 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3223",
      "3223 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3224",
      "3224 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3225",
      "3225 - Length or first dimension, feet (variable measure trade item)",
      "N6",
      "LENGTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3230",
      "3230 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3231",
      "3231 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3232",
      "3232 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3233",
      "3233 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3234",
      "3234 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3235",
      "3235 - Length or first dimension, yards (variable measure trade item)",
      "N6",
      "LENGTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3240",
      "3240 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3241",
      "3241 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3242",
      "3242 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3243",
      "3243 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3244",
      "3244 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3245",
      "3245 - Width, diameter, or second dimension, inches (variable measure trade item)",
      "N6",
      "WIDTH (in)",
      "(\\d{6})$"
   ],
   [
      "3250",
      "3250 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3251",
      "3251 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3252",
      "3252 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3253",
      "3253 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3254",
      "3254 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3255",
      "3255 - Width, diameter, or second dimension, feet (variable measure trade item)",
      "N6",
      "WIDTH (ft)",
      "(\\d{6})$"
   ],
   [
      "3260",
      "3260 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3261",
      "3261 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3262",
      "3262 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3263",
      "3263 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3264",
      "3264 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3265",
      "3265 - Width, diameter, or second dimension, yards (variable measure trade item)",
      "N6",
      "WIDTH (yd)",
      "(\\d{6})$"
   ],
   [
      "3270",
      "3270 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3271",
      "3271 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3272",
      "3272 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3273",
      "3273 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3274",
      "3274 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3275",
      "3275 - Depth, thickness, height, or third dimension, inches (variable measure trade item)",
      "N6",
      "HEIGHT (in)",
      "(\\d{6})$"
   ],
   [
      "3280",
      "3280 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3281",
      "3281 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3282",
      "3282 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3283",
      "3283 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3284",
      "3284 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3285",
      "3285 - Depth, thickness, height, or third dimension, feet (variable measure trade item)",
      "N6",
      "HEIGHT (ft)",
      "(\\d{6})$"
   ],
   [
      "3290",
      "3290 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3291",
      "3291 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3292",
      "3292 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3293",
      "3293 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3294",
      "3294 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3295",
      "3295 - Depth, thickness, height, or third dimension, yards (variable measure trade item)",
      "N6",
      "HEIGHT (yd)",
      "(\\d{6})$"
   ],
   [
      "3300",
      "3300 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3301",
      "3301 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3302",
      "3302 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3303",
      "3303 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3304",
      "3304 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3305",
      "3305 - Logistic weight, kilograms",
      "N6",
      "GROSS WEIGHT (kg)",
      "(\\d{6})$"
   ],
   [
      "3310",
      "3310 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3311",
      "3311 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3312",
      "3312 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3313",
      "3313 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3314",
      "3314 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3315",
      "3315 - Length or first dimension, metres",
      "N6",
      "LENGTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3320",
      "3320 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3321",
      "3321 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3322",
      "3322 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3323",
      "3323 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3324",
      "3324 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3325",
      "3325 - Width, diameter, or second dimension, metres",
      "N6",
      "WIDTH (m), log",
      "(\\d{6})$"
   ],
   [
      "3330",
      "3330 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3331",
      "3331 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3332",
      "3332 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3333",
      "3333 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3334",
      "3334 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3335",
      "3335 - Depth, thickness, height, or third dimension, metres",
      "N6",
      "HEIGHT (m), log",
      "(\\d{6})$"
   ],
   [
      "3340",
      "3340 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3341",
      "3341 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3342",
      "3342 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3343",
      "3343 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3344",
      "3344 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3345",
      "3345 - Area, square metres",
      "N6",
      "AREA (m2), log",
      "(\\d{6})$"
   ],
   [
      "3350",
      "3350 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3351",
      "3351 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3352",
      "3352 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3353",
      "3353 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3354",
      "3354 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3355",
      "3355 - Logistic volume, litres",
      "N6",
      "VOLUME (l), log",
      "(\\d{6})$"
   ],
   [
      "3360",
      "3360 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3361",
      "3361 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3362",
      "3362 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3363",
      "3363 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3364",
      "3364 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3365",
      "3365 - Logistic volume, cubic metres",
      "N6",
      "VOLUME (m3), log",
      "(\\d{6})$"
   ],
   [
      "3370",
      "3370 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3371",
      "3371 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3372",
      "3372 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3373",
      "3373 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3374",
      "3374 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3375",
      "3375 - Kilograms per square metre",
      "N6",
      "KG PER m2",
      "(\\d{6})$"
   ],
   [
      "3400",
      "3400 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3401",
      "3401 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3402",
      "3402 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3403",
      "3403 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3404",
      "3404 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3405",
      "3405 - Logistic weight, pounds",
      "N6",
      "GROSS WEIGHT (lb)",
      "(\\d{6})$"
   ],
   [
      "3410",
      "3410 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3411",
      "3411 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3412",
      "3412 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3413",
      "3413 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3414",
      "3414 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3415",
      "3415 - Length or first dimension, inches",
      "N6",
      "LENGTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3420",
      "3420 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3421",
      "3421 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3422",
      "3422 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3423",
      "3423 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3424",
      "3424 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3425",
      "3425 - Length or first dimension, feet",
      "N6",
      "LENGTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3430",
      "3430 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3431",
      "3431 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3432",
      "3432 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3433",
      "3433 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3434",
      "3434 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3435",
      "3435 - Length or first dimension, yards",
      "N6",
      "LENGTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3440",
      "3440 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3441",
      "3441 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3442",
      "3442 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3443",
      "3443 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3444",
      "3444 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3445",
      "3445 - Width, diameter, or second dimension, inches",
      "N6",
      "WIDTH (in), log",
      "(\\d{6})$"
   ],
   [
      "3450",
      "3450 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3451",
      "3451 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3452",
      "3452 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3453",
      "3453 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3454",
      "3454 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3455",
      "3455 - Width, diameter, or second dimension, feet",
      "N6",
      "WIDTH (ft), log",
      "(\\d{6})$"
   ],
   [
      "3460",
      "3460 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3461",
      "3461 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3462",
      "3462 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3463",
      "3463 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3464",
      "3464 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3465",
      "3465 - Width, diameter, or second dimension, yard",
      "N6",
      "WIDTH (yd), log",
      "(\\d{6})$"
   ],
   [
      "3470",
      "3470 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3471",
      "3471 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3472",
      "3472 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3473",
      "3473 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3474",
      "3474 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3475",
      "3475 - Depth, thickness, height, or third dimension, inches",
      "N6",
      "HEIGHT (in), log",
      "(\\d{6})$"
   ],
   [
      "3480",
      "3480 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3481",
      "3481 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3482",
      "3482 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3483",
      "3483 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3484",
      "3484 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3485",
      "3485 - Depth, thickness, height, or third dimension, feet",
      "N6",
      "HEIGHT (ft), log",
      "(\\d{6})$"
   ],
   [
      "3490",
      "3490 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3491",
      "3491 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3492",
      "3492 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3493",
      "3493 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3494",
      "3494 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3495",
      "3495 - Depth, thickness, height, or third dimension, yards",
      "N6",
      "HEIGHT (yd), log",
      "(\\d{6})$"
   ],
   [
      "3500",
      "3500 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3501",
      "3501 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3502",
      "3502 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3503",
      "3503 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3504",
      "3504 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3505",
      "3505 - Area, square inches (variable measure trade item)",
      "N6",
      "AREA (in2)",
      "(\\d{6})$"
   ],
   [
      "3510",
      "3510 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3511",
      "3511 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3512",
      "3512 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3513",
      "3513 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3514",
      "3514 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3515",
      "3515 - Area, square feet (variable measure trade item)",
      "N6",
      "AREA (ft2)",
      "(\\d{6})$"
   ],
   [
      "3520",
      "3520 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3521",
      "3521 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3522",
      "3522 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3523",
      "3523 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3524",
      "3524 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3525",
      "3525 - Area, square yards (variable measure trade item)",
      "N6",
      "AREA (yd2)",
      "(\\d{6})$"
   ],
   [
      "3530",
      "3530 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3531",
      "3531 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3532",
      "3532 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3533",
      "3533 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3534",
      "3534 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3535",
      "3535 - Area, square inches",
      "N6",
      "AREA (in2), log",
      "(\\d{6})$"
   ],
   [
      "3540",
      "3540 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3541",
      "3541 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3542",
      "3542 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3543",
      "3543 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3544",
      "3544 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3545",
      "3545 - Area, square feet",
      "N6",
      "AREA (ft2), log",
      "(\\d{6})$"
   ],
   [
      "3550",
      "3550 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3551",
      "3551 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3552",
      "3552 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3553",
      "3553 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3554",
      "3554 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3555",
      "3555 - Area, square yards",
      "N6",
      "AREA (yd2), log",
      "(\\d{6})$"
   ],
   [
      "3560",
      "3560 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3561",
      "3561 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3562",
      "3562 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3563",
      "3563 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3564",
      "3564 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3565",
      "3565 - Net weight, troy ounces (variable measure trade item)",
      "N6",
      "NET WEIGHT (t oz)",
      "(\\d{6})$"
   ],
   [
      "3570",
      "3570 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3571",
      "3571 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3572",
      "3572 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3573",
      "3573 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3574",
      "3574 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3575",
      "3575 - Net weight (or volume), ounces (variable measure trade item)",
      "N6",
      "NET VOLUME (oz)",
      "(\\d{6})$"
   ],
   [
      "3600",
      "3600 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3601",
      "3601 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3602",
      "3602 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3603",
      "3603 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3604",
      "3604 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3605",
      "3605 - Net volume, quarts (variable measure trade item)",
      "N6",
      "NET VOLUME (qt)",
      "(\\d{6})$"
   ],
   [
      "3610",
      "3610 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3611",
      "3611 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3612",
      "3612 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3613",
      "3613 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3614",
      "3614 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3615",
      "3615 - Net volume, gallons U.S. (variable measure trade item)",
      "N6",
      "NET VOLUME (gal.)",
      "(\\d{6})$"
   ],
   [
      "3620",
      "3620 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3621",
      "3621 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3622",
      "3622 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3623",
      "3623 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3624",
      "3624 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3625",
      "3625 - Logistic volume, quarts",
      "N6",
      "VOLUME (qt), log",
      "(\\d{6})$"
   ],
   [
      "3630",
      "3630 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3631",
      "3631 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3632",
      "3632 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3633",
      "3633 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3634",
      "3634 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3635",
      "3635 - Logistic volume, gallons U.S.",
      "N6",
      "VOLUME (gal.), log",
      "(\\d{6})$"
   ],
   [
      "3640",
      "3640 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3641",
      "3641 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3642",
      "3642 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3643",
      "3643 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3644",
      "3644 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3645",
      "3645 - Net volume, cubic inches (variable measure trade item)",
      "N6",
      "VOLUME (in3)",
      "(\\d{6})$"
   ],
   [
      "3650",
      "3650 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3651",
      "3651 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3652",
      "3652 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3653",
      "3653 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3654",
      "3654 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3655",
      "3655 - Net volume, cubic feet (variable measure trade item)",
      "N6",
      "VOLUME (ft3)",
      "(\\d{6})$"
   ],
   [
      "3660",
      "3660 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3661",
      "3661 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3662",
      "3662 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3663",
      "3663 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3664",
      "3664 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3665",
      "3665 - Net volume, cubic yards (variable measure trade item)",
      "N6",
      "VOLUME (yd3)",
      "(\\d{6})$"
   ],
   [
      "3670",
      "3670 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3671",
      "3671 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3672",
      "3672 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3673",
      "3673 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3674",
      "3674 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3675",
      "3675 - Logistic volume, cubic inches",
      "N6",
      "VOLUME (in3), log",
      "(\\d{6})$"
   ],
   [
      "3680",
      "3680 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3681",
      "3681 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3682",
      "3682 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3683",
      "3683 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3684",
      "3684 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3685",
      "3685 - Logistic volume, cubic feet",
      "N6",
      "VOLUME (ft3), log",
      "(\\d{6})$"
   ],
   [
      "3690",
      "3690 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "3691",
      "3691 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "3692",
      "3692 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "3693",
      "3693 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "3694",
      "3694 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "3695",
      "3695 - Logistic volume, cubic yards",
      "N6",
      "VOLUME (yd3), log",
      "(\\d{6})$"
   ],
   [
      "37",
      "37 - Count of trade items or trade item pieces contained in a logistic unit",
      "N..8",
      "COUNT",
      "^(\\d{0,8})$"
   ],
   [
      "3900",
      "3900 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3901",
      "3901 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3902",
      "3902 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3903",
      "3903 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3904",
      "3904 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3905",
      "3905 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3906",
      "3906 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3907",
      "3907 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3908",
      "3908 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3909",
      "3909 - Applicable amount payable or Coupon value, local currency",
      "N..15",
      "AMOUNT",
      "(\\d{0,15})$"
   ],
   [
      "3910",
      "3910 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3911",
      "3911 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3912",
      "3912 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3913",
      "3913 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3914",
      "3914 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3915",
      "3915 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3916",
      "3916 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3917",
      "3917 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3918",
      "3918 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3919",
      "3919 - Applicable amount payable with ISO currency code",
      "N..15",
      "AMOUNT",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3920",
      "3920 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3921",
      "3921 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3922",
      "3922 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3923",
      "3923 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3924",
      "3924 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3925",
      "3925 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3926",
      "3926 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3927",
      "3927 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3928",
      "3928 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3929",
      "3929 - Applicable amount payable, single monetary area (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{0,15})$"
   ],
   [
      "3930",
      "3930 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3931",
      "3931 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3932",
      "3932 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3933",
      "3933 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3934",
      "3934 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3935",
      "3935 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3936",
      "3936 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3937",
      "3937 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3938",
      "3938 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3939",
      "3939 - Applicable amount payable with ISO currency code (variable measure trade item)",
      "N..15",
      "PRICE",
      "(\\d{3})(\\d{0,15})$"
   ],
   [
      "3940",
      "3940 - Percentage discount of a coupon",
      "N4",
      "PRCNT OFF",
      "(\\d{4})$"
   ],
   [
      "3941",
      "3941 - Percentage discount of a coupon",
      "N4",
      "PRCNT OFF",
      "(\\d{4})$"
   ],
   [
      "3942",
      "3942 - Percentage discount of a coupon",
      "N4",
      "PRCNT OFF",
      "(\\d{4})$"
   ],
   [
      "3943",
      "3943 - Percentage discount of a coupon",
      "N4",
      "PRCNT OFF",
      "(\\d{4})$"
   ],
   [
      "3950",
      "3950 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3951",
      "3951 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3952",
      "3952 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3953",
      "3953 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3954",
      "3954 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3955",
      "3955 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3956",
      "3956 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3957",
      "3957 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3958",
      "3958 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "3959",
      "3959 - Amount Payable per unit of measure single monetary area (variable measure trade item)",
      "N6",
      "PRICE/UoM",
      "(\\d{0,6})$"
   ],
   [
      "400",
      "400 - Customers purchase order number",
      "X..30",
      "ORDER NUMBER",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "401",
      "401 - Global Identification Number for Consignment (GINC)",
      "X..30",
      "GINC",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "402",
      "402 - Global Shipment Identification Number (GSIN)",
      "N17",
      "GSIN",
      "(\\d{17})$"
   ],
   [
      "403",
      "403 - Routing code",
      "X..30",
      "ROUTE",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "410",
      "410 - Ship to / Deliver to Global Location Number (GLN)",
      "N13",
      "SHIP TO LOC",
      "(\\d{13})$"
   ],
   [
      "411",
      "411 - Bill to / Invoice to Global Location Number (GLN)",
      "N13",
      "BILL TO",
      "(\\d{13})$"
   ],
   [
      "412",
      "412 - Purchased from Global Location Number (GLN)",
      "N13",
      "PURCHASE FROM",
      "(\\d{13})$"
   ],
   [
      "413",
      "413 - Ship for / Deliver for - Forward to Global Location Number (GLN)",
      "N13",
      "SHIP FOR LOC",
      "(\\d{13})$"
   ],
   [
      "414",
      "414 - Identification of a physical location - Global Location Number (GLN)",
      "N13",
      "LOC No",
      "(\\d{13})$"
   ],
   [
      "415",
      "415 - Global Location Number (GLN) of the invoicing party",
      "N13",
      "PAY TO",
      "(\\d{13})$"
   ],
   [
      "416",
      "416 - Global Location Number (GLN) of the production or service location",
      "N13",
      "PROD/SERV LOC",
      "(\\d{13})$"
   ],
   [
      "417",
      "417 - Party Global Location Number (GLN)",
      "N13",
      "PARTY",
      "(\\d{13})$"
   ],
   [
      "420",
      "420 - Ship to / Deliver to postal code within a single postal authority",
      "X..20",
      "SHIP TO POST",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "421",
      "421 - Ship to / Deliver to postal code with ISO country code",
      "X..9",
      "SHIP TO POST",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,9})$"
   ],
   [
      "422",
      "422 - Country of origin of a trade item",
      "N3",
      "ORIGIN",
      "(\\d{3})$"
   ],
   [
      "423",
      "423 - Country of initial processing",
      "N..12",
      "COUNTRY - INITIAL PROCESS.",
      "(\\d{3})(\\d{0,12})$"
   ],
   [
      "424",
      "424 - Country of processing",
      "N3",
      "COUNTRY - PROCESS.",
      "(\\d{3})$"
   ],
   [
      "425",
      "425 - Country of disassembly",
      "N..12",
      "COUNTRY - DISASSEMBLY",
      "(\\d{3})(\\d{0,12})$"
   ],
   [
      "426",
      "426 - Country covering full process chain",
      "N3",
      "COUNTRY - FULL PROCESS",
      "(\\d{3})$"
   ],
   [
      "427",
      "427 - Country subdivision Of origin",
      "X..3",
      "ORIGIN SUBDIVISION",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,3})$"
   ],
   [
      "4300",
      "4300 - Ship-to / Deliver-to company name",
      "X..35",
      "SHIP TO COMP",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})$"
   ],
   [
      "4301",
      "4301 - Ship-to / Deliver-to contact",
      "X..35",
      "SHIP TO NAME",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})$"
   ],
   [
      "4302",
      "4302 - Ship-to / Deliver-to address line 1",
      "X..70",
      "SHIP TO ADD1",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4303",
      "4303 - Ship-to / Deliver-to address line 2",
      "X..70",
      "SHIP TO ADD2",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4304",
      "4304 - Ship-to / Deliver-to suburb",
      "X..70",
      "SHIP TO SUB",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4305",
      "4305 - Ship-to / Deliver-to locality",
      "X..70",
      "SHIP TO LOC",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4306",
      "4306 - Ship-to / Deliver-to region",
      "X..70",
      "SHIP TO REG",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4307",
      "4307 - Ship-to / Deliver-to country code",
      "X2",
      "SHIP TO COUNTRY",
      "([A-Z]{2})$"
   ],
   [
      "4308",
      "4308 - Ship-to / Deliver-to telephone number",
      "X..30",
      "SHIP TO PHONE",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "4310",
      "4310 - Return-to company name",
      "X..35",
      "RTN TO COMP",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})$"
   ],
   [
      "4311",
      "4311 - Return-to contact",
      "X..35",
      "RTN TO NAME",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})$"
   ],
   [
      "4312",
      "4312 - Return-to address line 1",
      "X..70",
      "RTN TO ADD1",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4313",
      "4313 - Return-to address line 2",
      "X..70",
      "RTN TO ADD2",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4314",
      "4314 - Return-to suburb",
      "X..70",
      "RTN TO SUB",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4315",
      "4315 - Return-to locality",
      "X..70",
      "RTN TO LOC",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4316",
      "4316 - Return-to region",
      "X..70",
      "RTN TO REG",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "4317",
      "4317 - Return-to country code",
      "X2",
      "RTN TO COUNTRY",
      "([A-Z]{2})$"
   ],
   [
      "4318",
      "4318 - Return-to postal code",
      "X..20",
      "RTN TO POST",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "4319",
      "4319 - Return-to telephone number",
      "X..30",
      "RTN TO PHONE",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "4320",
      "4320 - Service code description",
      "X..35",
      "SRV DESCRIPTION",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,35})$"
   ],
   [
      "4321",
      "4321 - Dangerous goods flag",
      "N1",
      "DANGEROUS GOODS",
      "([01])$"
   ],
   [
      "4322",
      "4322 - Authority to leave",
      "N1",
      "AUTH TO LEAVE",
      "([01])$"
   ],
   [
      "4323",
      "4323 - Signature required flag",
      "N1",
      "SIG REQUIRED",
      "([01])$"
   ],
   [
      "4324",
      "4324 - Not before delivery date time",
      "N10",
      "NOT BEF DEL DT",
      "(\\d{10})$"
   ],
   [
      "4325",
      "4325 - Not after delivery date time",
      "N10",
      "NOT AFT DEL DT",
      "(\\d{10})$"
   ],
   [
      "4326",
      "4326 - Release date",
      "N6",
      "REL DATE",
      "(\\d{6})$"
   ],
   [
      "7001",
      "7001 - NATO Stock Number (NSN)",
      "N13",
      "NSN",
      "(\\d{13})$"
   ],
   [
      "7002",
      "7002 - UN/ECE meat carcasses and cuts classification",
      "X..30",
      "MEAT CUT",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "7003",
      "7003 - Expiration date and time",
      "N10",
      "EXPIRY TIME",
      "(\\d{10})$"
   ],
   [
      "7004",
      "7004 - Active potency",
      "N..4",
      "ACTIVE POTENCY",
      "(\\d{0,4})$"
   ],
   [
      "7005",
      "7005 - Catch area",
      "X..12",
      "CATCH AREA",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,12})$"
   ],
   [
      "7006",
      "7006 - First freeze date",
      "N6",
      "FIRST FREEZE DATE",
      "(\\d{6})$"
   ],
   [
      "7007",
      "7007 - Harvest date",
      "N6..12",
      "HARVEST DATE",
      "(\\d{6,12})$"
   ],
   [
      "7008",
      "7008 - Species for fishery purposes",
      "X..3",
      "AQUATIC SPECIES",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,3})$"
   ],
   [
      "7009",
      "7009 - Fishing gear type",
      "X..10",
      "FISHING GEAR TYPE",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,10})$"
   ],
   [
      "7010",
      "7010 - Production method",
      "X..2",
      "PROD METHOD",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,2})$"
   ],
   [
      "7020",
      "7020 - Refurbishment lot ID",
      "X..20",
      "REFURB LOT",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "7021",
      "7021 - Functional status",
      "X..20",
      "FUNC STAT",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "7022",
      "7022 - Revision status",
      "X..20",
      "REV STAT",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "7023",
      "7023 - Global Individual Asset Identifier (GIAI) of an assembly",
      "X..30",
      "GIAI - ASSEMBLY",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "7030",
      "7030 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 0",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7031",
      "7031 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 1",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7032",
      "7032 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 2",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7033",
      "7033 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 3",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7034",
      "7034 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 4",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7035",
      "7035 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 5",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7036",
      "7036 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 6",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7037",
      "7037 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 7",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7038",
      "7038 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 8",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7039",
      "7039 - Number of processor with ISO Country Code",
      "X..27",
      "PROCESSOR # 9",
      "(\\d{3})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,27})$"
   ],
   [
      "7040",
      "7040 - GS1 UIC with Extension 1 and Importer index",
      "N1+X3",
      "UIC+EXT",
      "(\\d[\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x41-\\x5A\\x5F\\x61-\\x7A]{3})$"
   ],
   [
      "710",
      "710 - National Healthcare Reimbursement Number (NHRN) - Germany PZN",
      "X..20",
      "NHRN PZN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "711",
      "711 - National Healthcare Reimbursement Number (NHRN) - France CIP",
      "X..20",
      "NHRN CIP",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "712",
      "712 - National Healthcare Reimbursement Number (NHRN) - Spain CN",
      "X..20",
      "NHRN CN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "713",
      "713 - National Healthcare Reimbursement Number (NHRN) - Brasil DRN",
      "X..20",
      "NHRN DRN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "714",
      "714 - National Healthcare Reimbursement Number (NHRN) - Portugal AIM",
      "X..20",
      "NHRN AIM",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "7230",
      "7230 - Certification reference",
      "X2+X..28",
      "CERT #1",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7231",
      "7231 - Certification reference",
      "X2+X..28",
      "CERT #2",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7232",
      "7232 - Certification reference",
      "X2+X..28",
      "CERT #3",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7233",
      "7233 - Certification reference",
      "X2+X..28",
      "CERT #4",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7234",
      "7234 - Certification reference",
      "X2+X..28",
      "CERT #5",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7235",
      "7235 - Certification reference",
      "X2+X..28",
      "CERT #6",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7236",
      "7236 - Certification reference",
      "X2+X..28",
      "CERT #7",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7237",
      "7237 - Certification reference",
      "X2+X..28",
      "CERT #8",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7238",
      "7238 - Certification reference",
      "X2+X..28",
      "CERT #9",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7239",
      "7239 - Certification reference",
      "X2+X..28",
      "CERT #10",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{2,30})$"
   ],
   [
      "7240",
      "7240 - Protocol ID",
      "X..20",
      "PROTOCOL",
      " ([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "8001",
      "8001 - Roll products (width, length, core diameter, direction, splices)",
      "N14",
      "DIMENSIONS",
      "(\\d{14})$"
   ],
   [
      "8002",
      "8002 - Cellular mobile telephone identifier",
      "X..20",
      "CMT No",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "8003",
      "8003 - Global Returnable Asset Identifier (GRAI)",
      "N14+X..16",
      "GRAI",
      "(\\d{14})([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,16})$"
   ],
   [
      "8004",
      "8004 - Global Individual Asset Identifier (GIAI)",
      "X..30",
      "GIAI",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "8005",
      "8005 - Price per unit of measure",
      "N6",
      "PRICE PER UNIT",
      "(\\d{6})$"
   ],
   [
      "8006",
      "8006 - Identification of an individual trade item piece",
      "N14+N2",
      "ITIP",
      "(\\d{14})(\\d{2})(\\d{2})$"
   ],
   [
      "8007",
      "8007 - International Bank Account Number (IBAN)",
      "X..34",
      "IBAN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,34})$"
   ],
   [
      "8008",
      "8008 - Date and time of production",
      "N8+N..4",
      "PROD TIME",
      "(\\d{8})(\\d{0,4})$"
   ],
   [
      "8009",
      "8009 - Optically Readable Sensor Indicator",
      "X..50",
      "OPTSEN",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,50})$"
   ],
   [
      "8010",
      "8010 - Component/Part Identifier (CPID)",
      "Y..30",
      "CPID",
      "([\\x23\\x2D\\x2F\\x30-\\x39\\x41-\\x5A]{0,30})$"
   ],
   [
      "8011",
      "8011 - Component/Part Identifier serial number (CPID SERIAL)",
      "N..12",
      "CPID SERIAL",
      "(\\d{0,12})$"
   ],
   [
      "8012",
      "8012 - Software version",
      "X..20",
      "VERSION",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,20})$"
   ],
   [
      "8013",
      "8013 - Global Model Number (GMN)",
      "X..30",
      "GMN (for medical devices, the default, global data title is BUDI-DI)",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "8017",
      "8017 - Global Service Relation Number (GSRN) to identify the relationship between an organisation offering services and the provider of services",
      "N18",
      "GSRN - PROVIDER",
      "(\\d{18})$"
   ],
   [
      "8018",
      "8018 - Global Service Relation Number (GSRN) to identify the relationship between an organisation offering services and the recipient of services",
      "N18",
      "GSRN - RECIPIENT",
      "(\\d{18})$"
   ],
   [
      "8019",
      "8019 - Service Relation Instance Number (SRIN)",
      "N..10",
      "SRIN",
      "(\\d{0,10})$"
   ],
   [
      "8020",
      "8020 - Payment slip reference number",
      "X..25",
      "REF No",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,25})$"
   ],
   [
      "8026",
      "8026 - Identification of pieces of a trade item (ITIP) contained in a logistic unit",
      "N14+N2",
      "ITIP CONTENT",
      "(\\d{14})(\\d{2})(\\d{2})$"
   ],
   [
      "8110",
      "8110 - Coupon code identification for use in North America",
      "X..70",
      "",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "8111",
      "8111 - Loyalty points of a coupon",
      "N4",
      "POINTS",
      "(\\d{4})$"
   ],
   [
      "8112",
      "8112 - Paperless coupon code identification for use in North America",
      "X..70",
      "",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "8200",
      "8200 - Extended Packaging URL",
      "X..70",
      "PRODUCT URL",
      "([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,70})$"
   ],
   [
      "90",
      "90 - Information mutually agreed between trading partners",
      "X..30",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,30})$"
   ],
   [
      "91",
      "91 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "92",
      "92 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "93",
      "93 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "94",
      "94 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "95",
      "95 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "96",
      "96 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "97",
      "97 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "98",
      "98 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ],
   [
      "99",
      "99 - Company internal information",
      "X..90",
      "INTERNAL",
      "^([\\x21-\\x22\\x25-\\x2F\\x30-\\x39\\x3A-\\x3F\\x41-\\x5A\\x5F\\x61-\\x7A]{0,90})$"
   ]
]

export default ai;