<template>
  <v-list dense>
    <v-list-item link to="/manager" exact>
      <v-list-item-action>
        <v-icon color="primary">mdi-chart-pie</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.dashboard")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/manager/companys">
      <v-list-item-action>
        <v-icon color="primary">mdi-briefcase</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.companys")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/manager/products">
      <v-list-item-action>
        <v-icon color="primary">mdi-cart</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.products")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="perm == 'manager'"
      link
      to="/manager/finances/membership"
    >
      <v-list-item-action>
        <v-icon color="primary">mdi-cash-multiple</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.membership_finances")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/finances/barcode">
      <v-list-item-action>
        <v-icon color="primary">mdi-cash-multiple</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.barcode_finances")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="perm == 'manager'"
      link
      to="/manager/invoices/membership"
    >
      <v-list-item-action>
        <v-icon color="primary">mdi-file</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.gs1_invoices")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/invoices/barcode">
      <v-list-item-action>
        <v-icon color="primary">mdi-file</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.gs1_solutions_invoices")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link @click="showGenerator">
      <v-list-item-action>
        <v-icon color="primary">mdi-barcode</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.ai_generator")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/site">
      <v-list-item-action>
        <v-icon color="primary">mdi-web</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.site_admin")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/mass/inbox">
      <v-list-item-action>
        <v-icon color="primary">mdi-email</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.sent_notifications")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/users">
      <v-list-item-action>
        <v-icon color="primary">mdi-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.users")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="perm == 'manager'" link to="/manager/administrators">
      <v-list-item-action>
        <v-icon color="primary">mdi-account-multiple</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.administrators")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link @click="logout">
      <v-list-item-action>
        <v-icon color="primary">mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.logout")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Auth from "@/helpers/authentication";
export default {
  data: function() {
    return {
      perm: Auth.isAuthenticated()["permission"],
    };
  },
  methods: {
    showGenerator() {
      this.$root.aiGeneratorState = true;
    },
    logout() {
      localStorage.clear();
      return (window.location.href = "/login");
    },
  },
};
</script>
